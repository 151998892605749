import React from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import slugify from "../helpers/slugify";
import Seo from "../components/global/Seo";

export default function Awards({ data }) {
  return (
    <div className="page-template-awards">
      <Seo seo={data.wpPage.seo} />
      <div className="wrapper">
        <h1 className="heading heading--x-large">Our Awards</h1>
        <section className="intro">
          {parse(data.wpPage.acfAwards.awardsIntroText)}
        </section>
        {data.wpPage.acfAwards.awards.map((year) => (
          <section className="year">
            <div className="capsule">{year.year}</div>
            {year.awardsYear.map((award) => {
              {
                console.log(award);
              }
              return (
                <article className={`award award-${slugify(award.awardType)}`}>
                  <span className="awarded_by">{award.awardedBy}</span>
                  <span className="beer">
                    {award?.drink?.title || award?.oldBeer} -{" "}
                    <span className="awardType">{award.awardType}</span>
                  </span>
                </article>
              );
            })}
          </section>
        ))}
      </div>
    </div>
  );
}

export const AwardsQuery = graphql`
  query AwardsQuery {
    wpPage(title: { eq: "Awards" }) {
      seo {
        metaDesc
        twitterTitle
        twitterDescription
        opengraphDescription
        opengraphTitle
        title
        opengraphImage {
          publicUrl
        }
        twitterImage {
          publicUrl
        }
      }
      acfAwards {
        awardsIntroText
        awards {
          year
          awardsYear {
            awardType
            awardedBy
            drink {
              ... on WpProduct {
                id
                title
              }
            }
            oldBeer
          }
        }
      }
    }
  }
`;
